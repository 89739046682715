import React, { useEffect, useState } from "react";

export const ForgotPassword = ({ onLoginButtonClick }) => {
  const [email, setEmail] = useState();
  const [formError, setFormError] = useState();
  const [formSuccess, setFormSuccess] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    document.title = "ShotQuality | Forgot Password";
  }, []);

  const validateEmail = () => {
    let lastAtPos = email.lastIndexOf("@");
    let lastDotPos = email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
      return false;
    }
    return true;
  };

  const onSubmitClick = (e) => {
    e.preventDefault();
    if (!email || email === "") {
      setFormError("Please enter an email");
      return;
    }
    if (!validateEmail()) {
      setFormError("Email is not valid");
      return;
    }
    let opts = {
      email: email,
    };
    fetch("/api/reset_email", {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((r) => r.json())
      .then((response) => {
        if (response.status === "success") {
          setFormSuccess("You should be receiving an email soon with the next steps");
          setFormError("");
          setButtonDisabled(true);
        } else {
          setFormError("Some error occured. Are you sure you entered the right email?");
        }
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-5 m-auto text-center">
      <h2 className=" mb-4">Forgot Password </h2>
      <p className=" mb-3">We just need your registered email address to send your password reset</p>
      <div className="login pt-3">
        <form action="#">
          <div className="form-group">
            <div className="position-relative w-100">
              {formError ? <p className={"wrongDetails"}>{formError}</p> : <></>}
              {formSuccess ? <p className={"formSuccess"}>{formSuccess}</p> : <></>}
            </div>
            <div className="position-relative w-100 ">
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                className="form-control text-center"
              />
            </div>
          </div>
          <div className="form-group pt-2">
            <button
              type="submit"
              disabled={buttonDisabled}
              onClick={onSubmitClick}
              className="btn btn-primary w-100 btn-gradient"
            >
              Reset My Password <i className="fa lock" />
            </button>
            <div className="w-100 text-center pt-2">
              <hr />
              <a href="#login" className="back" onClick={onLoginButtonClick}>
                Back To Login
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
