import React from "react";
import { CSSTransition } from "react-transition-group";

export const FadeComponent = ({ show, children }) => {
  return (
    <CSSTransition in={show} timeout={300} classNames="fade">
      {show ? children : <></>}
    </CSSTransition>
  );
};
