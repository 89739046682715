import React, { useState } from "react";
import { AiFillCheckSquare, AiOutlineBorder } from "react-icons/ai";

const Checkbox = React.forwardRef((props, ref) => {
  const [isChecked, setIsChecked] = useState(props?.checked ?? false);

  const handleCheckboxClick = () => {
    return isChecked ? setIsChecked(false) : setIsChecked(true);
  };

  return (
    <div className="from-checkbox">
      <label className="d-flex align-items-center mb-0" onClick={handleCheckboxClick}>
        <span className="form-icon">
          {props?.checked ? (
            <AiFillCheckSquare
              style={{
                height: "1em",
                width: "1em",
                animation: "pulse 1s forwards",
              }}
            />
          ) : (
            <AiOutlineBorder
              style={{
                width: "1em",
                height: "1em",
                color: "gray",
                opacity: "0.7",
              }}
            />
          )}
        </span>
        <input type="checkbox" {...props} className="d-none" ref={ref} />
        <span className="ml-3">{props?.label ?? ""}</span>
      </label>
    </div>
  );
});

Checkbox.displayName = "Checkbox";

export default Checkbox;
