import React, { useEffect, useRef, useCallback } from "react";

const useTimeout = (callback, delay) => {
  const timeoutIdRef = useRef();
  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = undefined;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);

  useEffect(() => {
    timeoutIdRef.current = setTimeout(callback, delay);
    return cancel;
  }, [callback, delay, cancel]);
};

export default useTimeout;
