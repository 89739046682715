import React, { useContext, useEffect, useState } from "react";
import { parseCookies } from "nookies";
import { useRouter } from "next/router";
import { login } from "../auth";
import Utils from "../../utils/Utils";
import Input from "../form/Input";
import { AiOutlineMail, AiOutlineKey, AiFillWarning } from "react-icons/ai";
import AppService from "../../services/AppService";
import { AppContext } from "../../context/state";
import { authGetRequest } from "../../utils/authGetRequest";
import { useCookies } from "react-cookie";
import { destroyCookie } from "nookies";

const Login = () => {
  const [authToken, setAuthToken] = useState(null);
  const [cookies, setCookie] = useCookies(["token_access, name"]);
  useEffect(() => {
    setAuthToken(document.cookie.split("access_token=").pop().split(";").shift());
  }, []);
  // const history = useHistory()
  const router = useRouter();
  const { store, setStore } = useContext(AppContext);

  const getQueryVariable = (variable) => {
    if (window) {
      var query = window.location.search.substring(1);
    }
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      // console.log(pair) //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };

  useEffect(() => {
    if (getQueryVariable("verify")) {
      if (getQueryVariable("verify").toLowerCase() == "true") {
        setFormSuccess("Email successfully verified. Please login below");
        setEmail(getQueryVariable("email"));
      } else if (getQueryVariable("verify").toLowerCase() == "false") {
        setFormError("Email verification failed");
      } else {
        return;
      }
    }
  }, []);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [formError, setFormError] = useState();
  const [formSuccess, setFormSuccess] = useState();
  const [loginClicked, setLoginClicked] = useState(false);

  const [username, setUsername] = Utils.useStickyState(null, "username");

  const [hasPaid, setHasPaid] = Utils.useStickyState(false, "has_paid");

  const [onTrial, setOnTrial] = Utils.useStickyState(false, "on_trial");

  const [registeredDate, setRegisteredDate] = Utils.useStickyState(false, "registered_date");

  const [userRoles, setUserRoles] = Utils.useStickyState(null, "user_roles");

  // useEffect(() => {
  //   document.title = 'ShotQuality Bets | Login'
  //   if (username) {
  //     router.pathname.replace('/')
  //   }
  // }, [router, username])

  const validateEmail = () => {
    let lastAtPos = email.lastIndexOf("@");
    let lastDotPos = email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
      return false;
    }
    return true;
  };

  // const appService = new AppService()

  const onSubmitClick = (e) => {
    e.preventDefault();
    setLoginClicked(true);
    if (!email || email == "") {
      setFormError("Please enter an email");
      return setLoginClicked(false);
    }
    if (!validateEmail()) {
      setFormError("Email is not valid");
      return setLoginClicked(false);
    }
    if (!password || password == "") {
      setFormError("You didn't enter any password");
      return setLoginClicked(false);
    }
    let opts = {
      email: email,
      password: password,
    };
    fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/login`, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((r) => r.json())
      .then(async (token) => {
        if (token.access_token) {
          const appService = new AppService(token.access_token);
          let teste = await appService.get("api/account");
          localStorage.setItem("user", JSON.stringify(teste));

          localStorage.setItem("school", token.school ?? "");
          // appService.setToken(token);
          // setUsername(token.name)
          // setUserRoles(token.user_roles)
          // setHasPaid(token.has_paid)
          // setOnTrial(token.on_trial)
          // setRegisteredDate(token.registered_date)
          // setStore({ ...store, isSignUpCoach: token.coach, user: token.name })
          appService.setUser(token.name);
          // setCookie(null, "access_token", token.access_token, {
          //   maxAge: 30 * 24 * 60 * 60,
          //   path: "/",
          // });
          // setCookie(null, "name", token.name, {
          //   maxAge: 30 * 24 * 60 * 60,
          //   path: "/",
          // });
          let d = new Date();
          d.setTime(d.getTime() + 1440 * 60 * 1000 * 30);
          setCookie("token_access", token.access_token, {
            path: "/",
            expires: d,
          });
          setCookie("name", token.name, { path: "/", expires: d });

          const a = document.createElement("a");
          // const preLoginUrl = localStorage.getItem("preLoginUrl");
          // a.href = preLoginUrl ? preLoginUrl : "/nba/shotquality-scores";
          a.href = "/ncaa/shotquality-scores";
          localStorage.setItem("preLoginUrl", "/");
          a.click();
          // nookies.set({ res: ctx.res }, 'access_token', token.access_token, {
          //   path: '/',
          //   maxAge: 31536000,
          //   httpOnly: true,
          // })
          // console.log(55, store)
          // login(token)

          // if (token.has_paid) {
          //   router.pathname.replace('/')
          // } else {
          //   router.pathname.replace('/upgrade-plan')
          // }
          // setTimeout(() => {
          //   window.location.reload()
          // }, 1000)
        } else {
          setFormError("You entered wrong email/password");
          console.log("Please type in correct username/password");
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoginClicked(false);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-6 m-auto">
      <h2 className="text-center mb-2">Members sign in to access the best data in Basketball</h2>
      <div className="login pt-5 pb-4">
        {formError ? (
          <p className={"wrongDetails"}>
            <AiFillWarning className="mr-2" />
            {formError}
          </p>
        ) : (
          <></>
        )}
        <form action="#">
          <Input
            type="email"
            placeholder="Email"
            // icon={<AiOutlineMail />}
            value={email}
            onChange={handleEmailChange}
            disabled={loginClicked}
            autoComplete="on"
          />
          <Input
            type="password"
            placeholder="Password"
            // icon={<AiOutlineKey />}
            value={password}
            onChange={handlePasswordChange}
            disabled={loginClicked}
            autoComplete="on"
          />
          <div className="form-group">
            <button
              className="btn btn-lg btn-primary w-100"
              onClick={onSubmitClick}
              type="submit"
              disabled={loginClicked}
            >
              Log In
            </button>
          </div>
          <div className="form-group d-flex justify-content-center">
            <a href="#forgot">
              <span className="text-center text-bold">Forgot password?</span>
            </a>
          </div>
          <hr />
        </form>
        <div className="row text-center d-flex justify-center">
          <div className="col-12">
            <h4>
              <u>Not a Member? Activate your membership to access:</u>
            </h4>
          </div>
          <div className="col-sm-12  text-left">
            <div>
              <p>✅ Live scores for selected games showing live regression stats to power your live picks.</p>
              <p>
                ✅ The world's largest database of NCAA player location data powering the sharpest predicted scores.
              </p>
              <p>✅ Access to the matchup simulator, betting analysis, regression statistics, and more.</p>
              <p>✅ Members have early access to live picks and members only content in Discord.</p>
            </div>
          </div>
        </div>
        <div className="form-group">
          <a href="/welcome">
            <button className="btn btn-lg btn-primary w-100 btn-gradient">Sign Up</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
