import React from "react";

const Dropdown = React.forwardRef((props, ref) => {
  return (
    <div {...props} className={`Dropdown ${props.className ?? ""}`} ref={ref}>
      {props.children}
    </div>
  );
});

Dropdown.displayName = "Dropdown";

Dropdown.Item = React.forwardRef((props, ref) => (
  <div {...props} className={`DropdownItem ${props.className ?? ""}`} ref={ref}>
    {" "}
    {props.children}{" "}
  </div>
));

Dropdown.Item.displayName = "Dropdown.Item";

Dropdown.Menu = React.forwardRef((props, ref) => (
  <div {...props} className={`DropdownMenu ${props.className ?? ""}`} ref={ref}>
    {" "}
    {props.children}{" "}
  </div>
));

Dropdown.Menu.displayName = "Dropdown.Menu";

Dropdown.Header = (props) => (
  <button {...props} className={`DropdownHeader btn ${props?.className ?? ""}`} type="button">
    {" "}
    {props.children}{" "}
  </button>
);

Dropdown.Header.displayName = "Dropdown.Header";

export default Dropdown;
