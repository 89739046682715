import { createAuthProvider } from "react-token-auth";
import nookies, { parseCookies, destroyCookie, setCookie } from "nookies";
import { getAppService } from "../../utils/getAppService";

// export const [useAuth, authFetch, login, logout] = createAuthProvider({
//   access_tokenKey: "access_token",
//   onUpdateToken: (token) =>
//     fetch("/api/refresh", {
//       method: "POST",
//       body: token.access_token,
//     })
//       .then((r) => r.json())
//       .catch((e) => {
//         console.error(e);
//       }),
// });

// const appService = await getAppService();

// export const useAuth = () => {
//   const user = appService.getUser();

//   return [!!user];
// };

// export const useAuthFetch = (url, body) => {
//   if (body.method.toUpperCase() === "POST") {
//     const result = appService.post(url, body);
//     return result;
//   }

//   if (body.method.toUpperCase() === "GET") {
//     const result = appService.get(url, body);
//     return result;
//   }
// };

// export const login = (token) => {
//   localStorage.setItem("school", token.school ?? "");
//   appService.setToken(token);
//   setStore({ ...store, isSignUpCoach: token.coach, user: token.name });
//   appService.setUser(token.name);
//   setCookie(null, "access_token", token.access_token, {
//     maxAge: 30 * 24 * 60 * 60,
//     path: "/",
//   });
//   setCookie(null, "name", token.name, {
//     maxAge: 30 * 24 * 60 * 60,
//     path: "/",
//   });
// };

export const logout = () => {
  destroyCookie({}, "access_token");
  destroyCookie({}, "token_access");
  destroyCookie({}, "name");
  if (typeof localStorage !== "undefined") {
    localStorage.clear();
  }

  setTimeout(() => {
    window.location.reload();
  }, 1000);
};
