import React from "react";
import RegisterLoginBanner from "../../components/account/RegisterLoginBanner";
import MainLayout from "../../components/MainLayout/MainLayout";

const LoginPage = () => {
  return (
    <MainLayout>
      <div className="Loginside signup">
        <div className="container">
          <div className="bg p-5">
            <RegisterLoginBanner />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default LoginPage;
