import React, { useContext, useEffect, useState, useRef, Fragment, createRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useRouter } from "next/router";
import { AppContext } from "../../context/state";
import Utils from "../../utils/Utils";
import Input from "../form/Input";
import { login } from "../auth";
import { AiOutlineUser, AiOutlineDown, AiOutlineUp, AiOutlineMail, AiOutlineSearch } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import Checkbox from "../form/Checkbox";
import Dropdown from "../dropdown/Dropdown";
import useOnClickOutsie from "../hooks/useOnClickOutside";
import useTimeout from "../hooks/useTimeout";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AppService from "../../services/AppService";
import "react-tabs/style/react-tabs.css";
import { event } from "../../lib/GoogleAnalytics";
import { authPostRequest } from "../../utils/authGetRequest";
import { loadStripe } from "@stripe/stripe-js";
import { getUTMParams } from "../../hooks/useUTMParams";

//videos
// import collegeMensConference from '../../assets/videos/team_ads/college_mens_conference.mp4'
// import collegeMensPartial from '../../assets/videos/team_ads/college_mens_partial.mp4'
// import collegeMensPremium from '../../assets/videos/team_ads/college_mens_premium.mp4'
// import collegeWomensPremium from '../../assets/videos/team_ads/college_womens_premium.mp4'
// import collegeWomensPartial from '../../assets/videos/team_ads/college_womens_partial.mp4'
// import collegeWomensConference from '../../assets/videos/team_ads/college_womens_conference.mp4'
// import nbaPremium from '../../assets/videos/team_ads/nba_premium.mp4'
// import nbaPartial from '../../assets/videos/team_ads/nba_partial.mp4'

//thumbnails
import collegeMensConferencePoster from "../../assets/videos/team_ads/college_mens_conference_poster.png";
import collegeMensPartialPoster from "../../assets/videos/team_ads/college_mens_partial_poster.png";
import collegeMensPremiumPoster from "../../assets/videos/team_ads/college_mens_premium_poster.png";
import collegeWomensPremiumPoster from "../../assets/videos/team_ads/college_womens_premium_poster.png";
import collegeWomensPartialPoster from "../../assets/videos/team_ads/college_womens_partial_poster.png";
import collegeWomensConferencePoster from "../../assets/videos/team_ads/college_womens_conference_poster.png";
import nbaPremiumPoster from "../../assets/videos/team_ads/nba_premium_poster.png";
import nbaPartialPoster from "../../assets/videos/team_ads/nba_partial_poster.png";
// import { setCookie } from 'nookies'
import { useCookies } from "react-cookie";

const leagueData = {
  nba: {
    label: "NBA",
    icon: Utils.getAsset("nba_icon.jpg"),
  },
  college_mens: {
    label: "COLLEGE MENS",
    icon: Utils.getAsset("ncaa_mens_icon.png"),
  },
  college_womens: {
    label: "COLLEGE WOMENS",
    icon: Utils.getAsset("ncaa_womens_icon.png"),
  },
};

export const Register = () => {
  const router = useRouter();
  let referredBy;
  if (global?.window) {
    referredBy = new URLSearchParams(window.location.search).get("invitedBy");

    if (localStorage.getItem("invitedBy") === null) {
      if (referredBy !== null) {
        localStorage.setItem("invitedBy", referredBy);
      }
    } else {
      referredBy = localStorage.getItem("invitedBy");
    }
  }

  const history = router.pathname;
  const { store, setStore } = useContext(AppContext);
  if (store.leagueType === "") {
    setStore({ ...store, leagueType: "nba" });
  }
  // const pathName = useLocation().pathname;
  const leagueRef = useRef(null);
  const teamOptionsRef = useRef(null);
  const videoPlayer = createRef();
  const teamOoptionsOutside = useOnClickOutsie(teamOptionsRef, () => {
    setShowOptList(false);
  });
  const [cookies, setCookie] = useCookies(["token_access, name"]);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [honeypot, setHoneypot] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [retypePassword, setRetypePassword] = useState();
  const [formError, setFormError] = useState();
  const [formSuccess, setFormSuccess] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [sortedTeamName, setSortedTeamName] = useState([]);
  const [searchTeamName, setSearchTeamName] = useState([]);
  const [sortedConfName, setSortedConfName] = useState([]);
  const [searchConfName, setSearchConfName] = useState([]);
  const [showOptList, setShowOptList] = useState(false);
  const [coachTeam, setCoachTeam] = useState("");
  const [currentTeam, setCurrentTeam] = useState("");
  const [confTeam, setConfTeam] = useState(new Set());
  const [partialTeam, setPartialTeam] = useState(new Set());
  const [showLeagueDropdown, toggleLeagueDropdown] = useState(false);
  const [fanAccess, setFanAccess] = useState(`${store.leagueType}_COACH_ACCESS`.toUpperCase());
  const [coachFanIndex, setCoachFanIndex] = useState(0);

  const [prices, setPrices] = useState([]);
  const [username, setUsername] = Utils.useStickyState(null, "username");

  const [hasPaid, setHasPaid] = Utils.useStickyState(false, "has_paid");

  const [onTrial, setOnTrial] = Utils.useStickyState(false, "on_trial");

  const [registeredDate, setRegisteredDate] = Utils.useStickyState(false, "registered_date");
  const stripePromise = loadStripe(process.env.NEXT_STRIPE_PUBLIC_KEY);

  useEffect(() => {
    Utils.readCsv("teams/Team_logo_name_defintions.csv")
      .then((response) => {
        if (response.length > 0) {
          let sortedTeams = response.map((dt) => dt["kenpom"]).sort();
          setSortedTeamName(sortedTeams);
        } else {
          throw new Error("Empty Team Records");
        }
      })
      .catch((err) => {
        console.error("Unable to Load Team CSV's");
      });
  }, []);

  useEffect(() => {
    document.title = "ShotQuality Bets | Register";
    // if (coachFanIndex === 0) {
    //   // Coach selected
    //   setStore({ ...store, isSignUpCoach: true });
    // } else {
    //   // Fan selected
    //   setStore({ ...store, isSignUpCoach: false });
    // }
    // fetch(`/api/${store.leagueType}/2021/team-standings`).then(response => {
    //   if (response.status != 200) return { data: [{ Team_Name: NaN }] };
    //   return response.json();
    // })
    //   .then(response => {
    //     let sortedResponse = response.data.map((elem, index) => {
    //       return elem.Team_Name;
    //     }).sort();
    //     let sortedConfResponse = response.conferences.sort();
    //     setSortedTeamName(sortedResponse);
    //     setSearchTeamName(sortedResponse);
    //     setSortedConfName(sortedConfResponse);
    //     setSearchConfName(sortedConfResponse);
    //     setPrices(response.prices);
    //   }).catch(e => {
    //     console.log("Request failed :(");
    //   });
  }, [coachFanIndex, setStore, store, store.leagueType]);

  const validateEmail = () => {
    let lastAtPos = email.lastIndexOf("@");
    let lastDotPos = email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
      return false;
    }
    return true;
  };

  const leagueChange = (e) => {
    setStore({ ...store, leagueType: typeof e === "object" ? e.target.value : e });
    toggleLeagueDropdown(false);
    // reset partial and conference team as league is changed
    setConfTeam(new Set());
    setPartialTeam(new Set());
  };
  const onSubmitClick = (e) => {
    setFormError();
    e.preventDefault();

    let fanTeams = [];
    if (fanAccess.includes("PARTIAL")) {
      fanTeams = Array.from(partialTeam);
    } else if (fanAccess.includes("CONFERENCE")) {
      fanTeams = Array.from(confTeam);
    } else {
      fanTeams = [];
    }
    const teams = store.isSignUpCoach ? [coachTeam] : fanTeams;

    let opts = {
      name: name,
      email: email,
      password: password,
      honeypot: honeypot,
      teams: teams,
      league: store.leagueType,
      type: "coach",
      subType: fanAccess,
      referredBy: referredBy,
    };

    if (!name || name == "") {
      setFormError("Please enter a name");
      return;
    }
    if (!email || email == "") {
      setFormError("Please enter an email");
      return;
    }
    if (!validateEmail()) {
      setFormError("Email is not valid");
      return;
    }
    if (!password || password == "") {
      setFormError("You didn't enter any password");
      return;
    }
    if (honeypot != "") {
      setFormError("Oooo don't try automating requests 😈");
      return;
    }

    // if (store.isSignUpCoach && !sortedTeamName.some(team => team == coachTeam)) {
    //   setFormError("Select a Valid Team");
    //   return;
    // }

    if (!termsAccepted || termsAccepted == false) {
      setFormError("Please accept the Terms & Conditions");
      return;
    }

    if (retypePassword !== password) {
      setFormError("Passwords don't match");
      return;
    }

    fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/register`, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((r) => r.json())
      .then(async (resp) => {
        if (resp.status === "success") {
          console.log("Successfully registered");
          const appService = new AppService(resp["access_token"]);
          appService.setUser(resp.name);
          setButtonDisabled(true);
          appService.setUser(resp.name);
          try {
            let userInfo = await appService.get("api/account");
            console.log({ userInfo });
            localStorage.setItem("user", JSON.stringify(userInfo));
          } catch (e) {
            console.error(e);
          }
          try {
            const utmParams = getUTMParams() || {};
            await authPostRequest("/api/utm", resp["access_token"], utmParams);
          } catch (err) {
            console.error(err);
          }

          // setCookie(null, 'access_token', resp.access_token, {
          //   maxAge: 30 * 24 * 60 * 60,
          //   path: '/',
          // })
          // setCookie(null, 'name', resp.name, {
          //   maxAge: 30 * 24 * 60 * 60,
          //   path: '/',
          // })
          let d = new Date();
          d.setTime(d.getTime() + 1440 * 60 * 1000 * 30);
          setCookie("token_access", resp.access_token, {
            path: "/",
            expires: d,
          });
          setCookie("name", resp?.name, { path: "/", expires: d });
          setTimeout(async () => {
            // If coming from pricing page, redirect to checkout
            if (router.query?.from === "/pricing") {
              const stripe = await stripePromise;
              const data = JSON.parse(router.query.data);
              authPostRequest(`/api/create-checkout-session`, resp["access_token"], data)
                .then((response) => response.data)
                .then(async (response) => {
                  try {
                    event({
                      action: "purchase",
                      params: data,
                    });
                  } catch (e) {
                    console.err(e);
                  }
                  const result = await stripe.redirectToCheckout({
                    sessionId: response.id,
                  });
                })
                .catch((err) => {
                  // setPaymentError("Already subscribed");
                  console.error(err);
                  router.push(router.query.from);
                });
            } else if (router.query?.from === "/live") {
              router.push(router.query.from + "?gid=" + router.query.gid);
            } else {
              router.push("/ncaa/shotquality-scores");
            }
          }, 1000);
        } else {
          if (resp.message) {
            setFormError(resp.message);
          } else {
            setFormError("An error occured. Please try again");
          }
        }
      });
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleHoneypotChange = (e) => {
    setHoneypot(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRetypePasswordChange = (e) => {
    setRetypePassword(e.target.value);
  };

  const searchTeams = (search) => {
    setCoachTeam(search);
    setShowOptList(true);
    setSearchTeamName(
      sortedTeamName.filter((team) => {
        return team.toLowerCase().includes(search.toLowerCase());
      })
    );
  };

  const searchConfTeams = (search) => {
    setCoachTeam(search);
    setShowOptList(true);
    setSearchConfName(
      sortedConfName.filter((team) => {
        return team.toLowerCase().includes(search.toLowerCase());
      })
    );
  };

  const onSelectKeyDown = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      setCoachTeam(searchTeamName[0]);
      setShowOptList(false);
    }
  };

  const handleTeamClick = (e) => {
    setCoachTeam(e.target.getAttribute("value") ?? "");
    setShowOptList(false);
  };

  const handleConfClick = (e) => {
    const conf = e.target.getAttribute("value");
    setCurrentTeam(conf);
    setConfTeam((previousState) => new Set([...previousState, conf]));
    setShowOptList(false);
  };
  const handlePartialClick = (e) => {
    const partialTeam = e.target.getAttribute("value");
    setCurrentTeam(partialTeam);
    setPartialTeam((previousState) => new Set([...previousState, partialTeam]));
    setShowOptList(false);
  };

  const onDeleteConfTeam = (conf) => {
    setConfTeam((prev) => new Set([...prev].filter((c) => c !== conf)));
  };
  const onDeletePartialTeam = (partial) => {
    setPartialTeam((prev) => new Set([...prev].filter((c) => c !== partial)));
  };
  const onSelectCoachFan = (index) => {
    if (index === 0) {
      // Coach selected
      setStore({ ...store, isSignUpCoach: true });
      setCoachFanIndex(0);
      setFanAccess(`${store.leagueType}_COACH_ACCESS`.toUpperCase());
    } else {
      // Fan selected
      setStore({ ...store, isSignUpCoach: false });
      setCoachFanIndex(1);
      setFanAccess(`${store.leagueType}_PARTIAL_FAN_ACCESS`.toUpperCase());
    }
  };

  const onSelectFanAccess = (index) => {
    if (index === 0) {
      // setStore({ ...store, fanAccess: "PartialAccess" })
      setFanAccess(`${store.leagueType}_PARTIAL_FAN_ACCESS`.toUpperCase());
    } else if (index === 1) {
      setFanAccess(`${store.leagueType}_CONFERENCE_FAN_ACCESS`.toUpperCase());
    } else {
      setFanAccess(`${store.leagueType}_FULL_FAN_ACCESS`.toUpperCase());
    }
  };

  // const getVideo = (videoType) => {
  //   if (store.leagueType === "nba") {
  //     if (videoType === "partial") {
  //       return nbaPartial;
  //     }
  //     if (videoType === "premium") {
  //       return nbaPremium;
  //     }
  //   } else if (store.leagueType === "college_mens") {
  //     if (videoType === "partial") {
  //       return collegeMensPartial;
  //     }
  //     if (videoType === "premium") {
  //       return collegeMensPremium;
  //     }
  //     if (videoType === "conference") {
  //       return collegeMensConference;
  //     }
  //   } else if (store.leagueType === "college_womens") {
  //     if (videoType === "partial") {
  //       return collegeWomensPartial;
  //     }
  //     if (videoType === "premium") {
  //       return collegeWomensPremium;
  //     }
  //     if (videoType === "conference") {
  //       return collegeWomensConference;
  //     }
  //   }
  // };
  const getPoster = (videoType) => {
    if (store.leagueType === "nba") {
      if (videoType === "partial") {
        return nbaPartialPoster;
      }
      if (videoType === "premium") {
        return nbaPremiumPoster;
      }
    } else if (store.leagueType === "college_mens") {
      if (videoType === "partial") {
        return collegeMensPartialPoster;
      }
      if (videoType === "premium") {
        return collegeMensPremiumPoster;
      }
      if (videoType === "conference") {
        return collegeMensConferencePoster;
      }
    } else if (store.leagueType === "college_womens") {
      if (videoType === "partial") {
        return collegeWomensPartialPoster;
      }
      if (videoType === "premium") {
        return collegeWomensPremiumPoster;
      }
      if (videoType === "conference") {
        return collegeWomensConferencePoster;
      }
    }
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-4 m-auto">
      {router.query?.from === "/live" ? (
        <h3 className="text-center mb-2">Sign Up to unlock free access ShotQualityBets live scores</h3>
      ) : (
        <h2 className="text-center mb-2">Sign Up</h2>
      )}
      <div className="position-relative w-100">
        {formError ? <p className={"wrongDetails"}>{formError}</p> : <></>}
        {formSuccess ? <p className={"formSuccess"}>{formSuccess}</p> : <></>}
      </div>

      <form action="#" className="pt-5">
        <Input icon={<AiOutlineUser />} type="text" placeholder="Name" onChange={handleNameChange} value={name} />
        <Input icon={<AiOutlineMail />} type="email" placeholder="Email" onChange={handleEmailChange} value={email} />
        <Input
          icon={<RiLockPasswordLine />}
          type="password"
          placeholder="Password"
          onChange={handlePasswordChange}
          value={password}
        />
        <Input
          icon={<RiLockPasswordLine />}
          type="password"
          placeholder="Retype Password"
          onChange={handleRetypePasswordChange}
          value={retypePassword}
        />
        <Checkbox
          checked={termsAccepted}
          onChange={() => {
            setTermsAccepted(!termsAccepted);
          }}
          label={
            <span className="form-group text-small" style={{ marginTop: "" }}>
              I have read and agree to the{" "}
              <a onClick={() => router.push("/terms-of-service")} target="_blank">
                terms &amp; conditions
              </a>{" "}
              and
              <a onClick={() => router.push("/privacy-policy")} target="_blank" className="d-block ml-5">
                privacy policy
              </a>
            </span>
          }
        />
        <div className="form-group col-sm-12 hidden">
          <div className="position-relative w-100">
            <i className="fa fa-lock" aria-hidden="true" />
            <input
              type="text"
              placeholder=""
              className="form-control"
              onChange={handleHoneypotChange}
              value={honeypot}
            />
          </div>
        </div>
        <div className="form-group">
          <button
            className="btn btn-lg btn-primary w-100 btn-gradient mt-3"
            onClick={onSubmitClick}
            disabled={buttonDisabled}
          >
            Sign Up
          </button>
        </div>
        <hr />
        <div className="form-group">
          <p className="text-center text-bold mt-3 w-100 text-center cur">
            Already have an account ?{" "}
            <a style={{ cursor: "pointer" }} onClick={() => router.push("/login#login")}>
              <strong>Log In</strong>
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};
