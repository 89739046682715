import React, { useState, useEffect } from "react";
import { Register } from "./Register";
import { useRouter } from "next/router";
import Login from "./Login";
import { FadeComponent } from "../fadediv/FadeComponent";
import { ForgotPassword } from "./ForgotPassword";

const RegisterLoginBanner = () => {
  const router = useRouter();
  const hashName = router.asPath.slice(router.asPath.indexOf("#"));
  const [showTab, setShowTab] = useState("login");

  useEffect(() => {
    if (hashName === "#login") {
      setShowTab("login");
    } else if (hashName === "#forgot") {
      setShowTab("forgot");
    } else {
      setShowTab("login");
    }
    document.title = "ShotQuality Bets | " + hashName;
  }, [hashName]);
  return (
    <div className="row no-gutters">
      <FadeComponent show={showTab === "register"}>
        <Register />
      </FadeComponent>
      <FadeComponent show={showTab === "login"}>
        <Login />
      </FadeComponent>
      <FadeComponent show={showTab === "forgot"}>
        <ForgotPassword
          onLoginButtonClick={() => {
            setShowTab("login");
          }}
        />
      </FadeComponent>
    </div>
  );
};

export default RegisterLoginBanner;
